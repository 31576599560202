
import { ThingsSyncQueryModel, ThingsTemplateEntityModel, ThingsTemplateQueryModel } from '@/entity-model/things-template-entity';
import { SyncType, ThingsEntityType, ThingsFunctionType } from '@/model/enum';
import { ICRUDQ } from '@/model/interface';
import { SimpleTreeModel } from '@/model/simple-tree-model';
import { get, post, put, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/things/template`;

class ThingsTemplateService implements ICRUDQ<ThingsTemplateEntityModel, ThingsTemplateQueryModel> {
    async create(model: ThingsTemplateEntityModel):Promise<ThingsTemplateEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ThingsTemplateEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ThingsTemplateEntityModel().toModel(res);
    }

    async update(model: ThingsTemplateEntityModel):Promise<ThingsTemplateEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThingsTemplateEntityModel):Promise<ThingsTemplateEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: ThingsTemplateQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ThingsTemplateEntityModel().toModel(item));
        return res;
    }

    async syncQuery(query?: ThingsSyncQueryModel, page?: number, limit?: number):Promise<any> {
        let url = null;
        if (query.thingsEntityType === ThingsEntityType.TEMPLATE) {
            url = `${URL_PATH}/sourceAndTargetList`;
        } else if (query.thingsEntityType === ThingsEntityType.FUNCTION) {
            url = `${BI_BASE_REQUEST_PATH}/things/function/sourceAndTargetList`;
        }
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ThingsTemplateEntityModel().toModel(item));
        return res;
    }

    async getSysnGroup(remoteUrl: string, token: string):Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/groups/sourceAndTargetGroupList?url=${remoteUrl}&type=1&token=${token}`;
        const res = await get(url);
        return {
            sourceGroups: _.map(_.get(res, 'sourceGroups'), item => {
                return new SimpleTreeModel().toModel(item);
            }),
            targetGroups: _.map(_.get(res, 'targetGroups'), item => {
                return new SimpleTreeModel().toModel(item);
            })
        };
    }

    async sync(remoteUrl: string, targetGroup: string, token: string, type: SyncType, thingsFunctionType: ThingsFunctionType, thingsEntityType: ThingsEntityType, templateList: Array<any>, isPreview: boolean = true):Promise<any> {
        let url = null;
        let params = null;
        if (thingsEntityType === ThingsEntityType.TEMPLATE) {
            if (isPreview) {
                url = `${URL_PATH}/preSync`;
            } else {
                url = `${URL_PATH}/sync`;
            }
            params = {
                url: remoteUrl,
                token,
                type,
                targetGroup,
                templateList
            };
        } else if (thingsEntityType === ThingsEntityType.FUNCTION) {
            if (isPreview) {
                url = `${BI_BASE_REQUEST_PATH}/things/function/preSync`;
            } else {
                url = `${BI_BASE_REQUEST_PATH}/things/function/sync`;
            }
            params = {
                url: remoteUrl,
                token,
                type,
                group: _.upperCase(thingsFunctionType)
            };
            switch (thingsFunctionType) {
                case ThingsFunctionType.ATTRIBUTE:
                    params.attrSyncList = templateList;
                    break;
                case ThingsFunctionType.EVENT:
                    params.eventSyncList = templateList;
                    break;
                case ThingsFunctionType.METHOD:
                    params.methodSyncList = templateList;
                    break;
            }
        }
        const res = await post(url, params);
        return res;
    }
}

export default new ThingsTemplateService();
